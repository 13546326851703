<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item.code" :key="index">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'
export default {
  mounted() {
    this.getList()
  },
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || this.$t(`请选择会计要素`)
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  methods: {
    async getList() {
      let res = await http({
        url: api.getAccountElementist,
        type: 'post',
        data: {
          level: 0,
        },
      })
      let list = res.result.list
      this.option = list
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (list.length === 1) {
        this.onChange(list[0].code)
      }
    },
    onChange(value) {
      this.selectedValue = value
      this.$emit('update:code', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  watch: {
    code(val) {
      this.selectedValue = val
    },
  },
}
</script>
